import React from "react";
import ReactPlayer from "react-player";

class YouTubePlayer extends React.Component {
  render() {
    return (
      <div>
        <a href="https://qrco.de/bffwrx?trackSharing=1" target="_blank"><p className='text-center mb-2'><b>How to Use Your Insulin Pen - Click to Learn</b></p></a>
        {/* <p><b>Click to Learn</b></p> */}
        {/* <ReactPlayer
          url="https://youtu.be/3zzEUi0xb10"
          controls
          width="100%"
          height="200px"
          max-width="400px"
        /> */}
        <a href="https://qrco.de/bffwrx?trackSharing=1" target="_blank">
          <div class="image-container">
            <img src="./assets/youtube_images/Picture1.png" className="main-image" />
            <img src="./assets/youtube_images/Picture2.png" className="overlay-image" />
          </div>
        </a>

        <p className='text-center mb-2'><b><u>Please fill up the information below</u> :</b></p>

      </div>
    );
  }
}

export default YouTubePlayer;
